* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1154px;
  margin: 0 auto;
  height: 100%;
  padding: 0 10px;
  position: relative;
}

.notify_progress_error,
.notify_progress_success {
  visibility: hidden;
}

.notify_error {
  background: #ed5669 !important;
  border: 3px solid #e71d36 !important;
}
.notify_body_error {
  flex-direction: row-reverse;
  color: #fff;
  font-weight: 500;
}

.notify_success {
  background: #41cbbe !important;
  border: 3px solid #158378 !important;
}
.notify_body_success {
  flex-direction: row-reverse;
  color: #fff;
  font-weight: 500;
}

.dylxVS {
  background-color: #41cbbe;
}
